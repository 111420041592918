header {
    background: white;
    .headerContentWrap {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
    }
    .clientLogo {
        height: auto;
        max-width: 200px;
    }
    .navBtn {
        font-size: 1rem;
        color: black;
        background: #ebebeb;
        padding: 0 20px;
        &.active {
            background: #57a7bb;
            color: white;
        }
    }
    .logoutBtn {
        font-size: 1rem;
        color: black;
        background: white;
        text-transform: capitalize;
        margin: 0;
        text-align: right;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            height: 20px;
            width: auto;
            margin: 0 0 0 10px;
        }
    }
    nav {
        display: flex;
        gap: 5px;
    }
}

@media (min-width: 768px) {
    header {
        .headerContentWrap {
            flex-direction: row;
            padding: 20px 0;
        }
    }
}