.clientDeleteModal {
  .contentWrap {
    max-width: 400px;
    .image {
      position: relative;
      margin: 20px 0 50px;
      img {
        width: 40px;
        display: block;
        margin: 0 auto;
        position: relative;
        z-index: 3;
      }
      .circle {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 999px;
        &.small {
          width: 60px;
          height: 60px;
          background: #f5d8da;
          z-index: 2;
        }
        &.large {
          width: 80px;
          height: 80px;
          background: #faebeb;
          z-index: 1;
        }
      }
    }
    h2 {
      text-align: center;
    }
    p {
      font-size: 1rem;
    }
    .btnGroup {
      display: flex;
      gap: 20px;
      button {
        font-size: 1rem;
      }
    }
  }
}