#AdminWrap {
    .contentWrap {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: flex-start;
        max-width: 1200px;
        margin-top: 20px;
        box-sizing: border-box;
        padding: 0 20px;
        gap: 20px;
        .addBtn {
            flex: 1 0 auto;
            max-width: 100%;
        }
    }
}


@media (min-width: 768px) {
    .contentWrap {
        min-width: 400px;
        max-width: 800px;
        margin: 0 auto;
    }
    #AdminWrap {
        .contentWrap {
            flex-direction: row;
            .addBtn {
                max-width: 300px;
            }
        }
    }
}