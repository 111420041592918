.table {
    flex: 0 1 100%;
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    .titles {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid lightgrey;
        color: grey;
        font-size: 0.8rem;
        text-transform: capitalize;
        padding: 10px;
        box-sizing: border-box;
        article {
            flex: 1;
            &:nth-child(1),
            &:nth-child(3) {
                flex: 0.5;
            }
            &:last-child {
                flex: 0.1;
                width: 50px;
                display: block;
            }
        }
    }
    .listingItem {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 1rem;
        padding: 10px;
        box-sizing: border-box;
        gap: 10px;
        article {
            flex: 1;
            display: flex;
            align-items: center;
            &:nth-child(1),
            &:nth-child(3) {
                flex: 0.5;
            }
            &:last-child {
                flex: 0.1;
                text-align: right;
                width: 50px;
            }
            &.clientID {
                img {
                    width: 10px;
                    height: auto;
                    margin: 0 5px 0 10px;
                    opacity: 0.5;
                    &:hover {
                        cursor: pointer;
                        opacity: 1;
                    }
                }
                span {
                    font-size: 0.6rem;
                }
            }
            .trashBtn {
                width: 30px;
                height: 30px;
                border-radius: 9999px;
                background: none;
                padding: 0;
                margin: 0;
                position: relative;
                z-index: 1;
                opacity: 0.5;
                img {
                    width: 50%;
                    pointer-events: none;
                }
                &:hover {
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }
        &:hover {
            cursor: pointer;
            background: #f7f6fa;
        }
    }
    &.users {
        .titles {
            article {
                flex: 0 1 100%;
                &:nth-child(3) {
                    flex: 1 0 30px;
                }
            }
        }
        .listingItem {
            article {
                flex: 0 1 50%;
                overflow: hidden;
                display: block;
                text-overflow: ellipsis;
                &:nth-child(3) {
                    flex: 1 0 30px;
                }
            }
        }
    }
}

@media (min-width: 768px) {
    .table {
        flex: 0 1 100%;
        background: white;
        border-radius: 10px;
        padding: 20px;
        box-sizing: border-box;
        gap: 20
    }
}