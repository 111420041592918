.modal.dataModal {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  .contentWrap {
    max-width: 800px;
    margin: 20px;
    h2 {
      text-align: center;
      margin: 0 0 40px;
    }
    .dataPointsForm {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .field {
        margin: 0 0 10px;
        width: 100%;
      }
    }
    .listResults {
      display: flex;
      flex-direction: column;
      max-height: 300px;
      overflow-y: scroll;
    }
    .titles {
      article {
        flex: 1;
        &:nth-child(1),
        &:nth-child(3) {
          flex: 1;
        }
        &:last-child {
          flex: 0.1;
          width: 50px;
          display: block;
        }
      }
    }
    .listingItem {
      padding: 5px 10px;
      article {
        &:nth-child(1),
        &:nth-child(3) {
          flex: 1;
        }
      }
      &:hover {
        cursor: default;
        background: initial;
      }
    }
  }
}

@media(min-width: 768px) {
  .modal.dataModal {
    justify-content: center;
    .contentWrap {
      margin: 0;
      .dataPointsForm {
        flex-direction: row;
        .field {
          margin: 0 10px 0 0;
        }
        button {
          flex: 0;
          margin: 0;
          padding: 0 20px;
        }
      }
    }
  }
}