#Login {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  height: 100vh;
  section {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    .logo {
      width: 200px;
    }
    &.left {
      background: white;
      box-sizing: border-box;
      padding: 20px;
      .logo {
        position: absolute;
        top: 30px;
        left: 30px;
      }
    }
    .loginFrmWrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      .field {
        margin-bottom: 20px;
      }
    }
    &.right {
      width: 100%;
      background-size: cover;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .formWrap {
    width: 100%;
    margin: 0 auto;
    button {
      margin: 20px 0 0;
    }
  }
}

@media(min-width: 768px) {
  #Login {
    flex-direction: row;
    section {
      &.left {
        .loginFrmWrap {
          height: 100%;
          max-width: 400px;
          display: flex;
          justify-content: center;
          margin: 0 auto;
        }
      }
    }
  }
}