.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(127,127,127,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    .contentWrap {
        background: white;
        padding: 20px;
        box-sizing: border-box;
        position: relative;
        border-radius: 20px;
        box-shadow: 0 5px 20px rgba(0,0,0,0.2);
        button {
            margin: 10px 0 0;
        }
    }
    h2 {
        margin: 0 0 10px;
    }
    .btnGroup {
        display: flex;
        gap: 20px;
        button {
            font-size: 1rem;
        }
        .cancelBtn {
            background: #f7f6fa;
            color: black;
            &:hover {
                background: #e8e7e7;
            }
        }
    }
    &.clientModal,
    &.userModal {
        .field {
            margin: 20px 0;
        }
    }
}