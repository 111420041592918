@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400&display=swap");
html,
body {
  font-family: sans-serif;
  background: #f6f7fa;
  margin: 0;
  padding: 0;
  color: #808285;
  font-family: "Poppins", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: black;
}

button {
  border: none;
  background: #57a7bb;
  color: white;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  font-weight: lighter;
  margin: 0;
  display: block;
  width: 100%;
  transition: all 0.2s ease-in-out;
  border-radius: 9999px;
}
button:hover {
  cursor: pointer;
  background: #408c9f;
  transition: all 0.2s ease-in-out;
}
button.cancelBtn {
  background: #f7f6fa;
  color: black;
}
button.cancelBtn:hover {
  background: #e8e7e7;
}
button.deleteBtn {
  background: #da3f41;
  color: white;
}
button.deleteBtn:hover {
  background: #c12527;
}

.field {
  position: relative;
}
.field label {
  display: block;
  text-transform: capitalize;
  font-size: 0.8rem;
  color: #808285;
  background: white;
  position: absolute;
  top: -50%;
  left: 10px;
  transform: translate(0, 100%);
  padding: 0 10px;
  letter-spacing: 1px;
}
.field input {
  width: 100%;
  font-size: 1.4rem;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  margin: 0;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  border-radius: 15px;
}

.capitalize {
  text-transform: capitalize;
}

.alert {
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  font-size: 0.8rem;
}
.alert.error {
  background: #da3f41;
  color: white;
}

header {
  background: white;
}
header .headerContentWrap {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
header .clientLogo {
  height: auto;
  max-width: 200px;
}
header .navBtn {
  font-size: 1rem;
  color: black;
  background: #ebebeb;
  padding: 0 20px;
}
header .navBtn.active {
  background: #57a7bb;
  color: white;
}
header .logoutBtn {
  font-size: 1rem;
  color: black;
  background: white;
  text-transform: capitalize;
  margin: 0;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
}
header .logoutBtn img {
  height: 20px;
  width: auto;
  margin: 0 0 0 10px;
}
header nav {
  display: flex;
  gap: 5px;
}

@media (min-width: 768px) {
  header .headerContentWrap {
    flex-direction: row;
    padding: 20px 0;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(127, 127, 127, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.modal .contentWrap {
  background: white;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  border-radius: 20px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}
.modal .contentWrap button {
  margin: 10px 0 0;
}
.modal h2 {
  margin: 0 0 10px;
}
.modal .btnGroup {
  display: flex;
  gap: 20px;
}
.modal .btnGroup button {
  font-size: 1rem;
}
.modal .btnGroup .cancelBtn {
  background: #f7f6fa;
  color: black;
}
.modal .btnGroup .cancelBtn:hover {
  background: #e8e7e7;
}
.modal.clientModal .field, .modal.userModal .field {
  margin: 20px 0;
}

#Login {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
#Login section {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
#Login section .logo {
  width: 200px;
}
#Login section.left {
  background: white;
  box-sizing: border-box;
  padding: 20px;
}
#Login section.left .logo {
  position: absolute;
  top: 30px;
  left: 30px;
}
#Login section .loginFrmWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
#Login section .loginFrmWrap .field {
  margin-bottom: 20px;
}
#Login section.right {
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
#Login .formWrap {
  width: 100%;
  margin: 0 auto;
}
#Login .formWrap button {
  margin: 20px 0 0;
}

@media (min-width: 768px) {
  #Login {
    flex-direction: row;
  }
  #Login section.left .loginFrmWrap {
    height: 100%;
    max-width: 400px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
}
#AdminWrap .contentWrap {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0 20px;
  gap: 20px;
}
#AdminWrap .contentWrap .addBtn {
  flex: 1 0 auto;
  max-width: 100%;
}

@media (min-width: 768px) {
  .contentWrap {
    min-width: 400px;
    max-width: 800px;
    margin: 0 auto;
  }
  #AdminWrap .contentWrap {
    flex-direction: row;
  }
  #AdminWrap .contentWrap .addBtn {
    max-width: 300px;
  }
}
.table {
  flex: 0 1 100%;
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
}
.table .titles {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid lightgrey;
  color: grey;
  font-size: 0.8rem;
  text-transform: capitalize;
  padding: 10px;
  box-sizing: border-box;
}
.table .titles article {
  flex: 1;
}
.table .titles article:nth-child(1), .table .titles article:nth-child(3) {
  flex: 0.5;
}
.table .titles article:last-child {
  flex: 0.1;
  width: 50px;
  display: block;
}
.table .listingItem {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  padding: 10px;
  box-sizing: border-box;
  gap: 10px;
}
.table .listingItem article {
  flex: 1;
  display: flex;
  align-items: center;
}
.table .listingItem article:nth-child(1), .table .listingItem article:nth-child(3) {
  flex: 0.5;
}
.table .listingItem article:last-child {
  flex: 0.1;
  text-align: right;
  width: 50px;
}
.table .listingItem article.clientID img {
  width: 10px;
  height: auto;
  margin: 0 5px 0 10px;
  opacity: 0.5;
}
.table .listingItem article.clientID img:hover {
  cursor: pointer;
  opacity: 1;
}
.table .listingItem article.clientID span {
  font-size: 0.6rem;
}
.table .listingItem article .trashBtn {
  width: 30px;
  height: 30px;
  border-radius: 9999px;
  background: none;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 1;
  opacity: 0.5;
}
.table .listingItem article .trashBtn img {
  width: 50%;
  pointer-events: none;
}
.table .listingItem article .trashBtn:hover {
  cursor: pointer;
  opacity: 1;
}
.table .listingItem:hover {
  cursor: pointer;
  background: #f7f6fa;
}
.table.users .titles article {
  flex: 0 1 100%;
}
.table.users .titles article:nth-child(3) {
  flex: 1 0 30px;
}
.table.users .listingItem article {
  flex: 0 1 50%;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
.table.users .listingItem article:nth-child(3) {
  flex: 1 0 30px;
}

@media (min-width: 768px) {
  .table {
    flex: 0 1 100%;
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    gap: 20;
  }
}
.modal.dataModal {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
}
.modal.dataModal .contentWrap {
  max-width: 800px;
  margin: 20px;
}
.modal.dataModal .contentWrap h2 {
  text-align: center;
  margin: 0 0 40px;
}
.modal.dataModal .contentWrap .dataPointsForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.modal.dataModal .contentWrap .dataPointsForm .field {
  margin: 0 0 10px;
  width: 100%;
}
.modal.dataModal .contentWrap .listResults {
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
}
.modal.dataModal .contentWrap .titles article {
  flex: 1;
}
.modal.dataModal .contentWrap .titles article:nth-child(1), .modal.dataModal .contentWrap .titles article:nth-child(3) {
  flex: 1;
}
.modal.dataModal .contentWrap .titles article:last-child {
  flex: 0.1;
  width: 50px;
  display: block;
}
.modal.dataModal .contentWrap .listingItem {
  padding: 5px 10px;
}
.modal.dataModal .contentWrap .listingItem article:nth-child(1), .modal.dataModal .contentWrap .listingItem article:nth-child(3) {
  flex: 1;
}
.modal.dataModal .contentWrap .listingItem:hover {
  cursor: default;
  background: initial;
}

@media (min-width: 768px) {
  .modal.dataModal {
    justify-content: center;
  }
  .modal.dataModal .contentWrap {
    margin: 0;
  }
  .modal.dataModal .contentWrap .dataPointsForm {
    flex-direction: row;
  }
  .modal.dataModal .contentWrap .dataPointsForm .field {
    margin: 0 10px 0 0;
  }
  .modal.dataModal .contentWrap .dataPointsForm button {
    flex: 0;
    margin: 0;
    padding: 0 20px;
  }
}
.clientDeleteModal .contentWrap {
  max-width: 400px;
}
.clientDeleteModal .contentWrap .image {
  position: relative;
  margin: 20px 0 50px;
}
.clientDeleteModal .contentWrap .image img {
  width: 40px;
  display: block;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}
.clientDeleteModal .contentWrap .image .circle {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 999px;
}
.clientDeleteModal .contentWrap .image .circle.small {
  width: 60px;
  height: 60px;
  background: #f5d8da;
  z-index: 2;
}
.clientDeleteModal .contentWrap .image .circle.large {
  width: 80px;
  height: 80px;
  background: #faebeb;
  z-index: 1;
}
.clientDeleteModal .contentWrap h2 {
  text-align: center;
}
.clientDeleteModal .contentWrap p {
  font-size: 1rem;
}
.clientDeleteModal .contentWrap .btnGroup {
  display: flex;
  gap: 20px;
}
.clientDeleteModal .contentWrap .btnGroup button {
  font-size: 1rem;
}

